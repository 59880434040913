import React, { useRef, useState } from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Sheet from "../components/sheet";
import Map from "../components/map";
import Description from "../components/description";
import Footer from "../components/footer";
import Mockup from "../components/mockup";
import VideoMockup from "../components/videoMockup";
import ArrowDown from "../components/arrowDown";
import Modal from "../components/modal";
import Seo from "../components/seo"

const SuperTitle = styled.div`
color: #fff;
z-index: 1;
justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    flex-direction:column;

    @media(min-width:1440px){
        h1{
            font-size: 7.5rem;
        }
    }

    h2{
        text-align: center;
    text-transform: uppercase;
    }
`
const Wrapper = styled.div`


.flux > div{
    padding-top: 100px;
}

.pt0{
    padding-top:0!important;
}

.map{
    padding-bottom: 100px;
    background-color: #F5F5F5;
}

@media(max-width:1024px){
    .pb-100{
    padding-bottom: 100px;
}
}

@media(min-width:1024px){
.flux > div{
    display:flex;
}
.sheet{
    min-height: 100vh;
}

.map{
    min-height: 100vh;
    display: flex;
    align-items: center;
}
}

.flux > div:first-child{
    padding-top: 0;
}

.sheet{
    overflow: hidden;
    width: 100vw;
}



span sub {
    display: block;
    padding-bottom: 6px;
}
`

// markup
const IndexPage = () => {
    const ref = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const toggleModal = () => {
        setModalIsOpen((oldIsOpen) => !oldIsOpen)
    }


    return (
        <>
            <div ref={ref}>
                <Layout>
                    <Seo title="Valentino Insights Roman Palazzo Art Residency - Vertual" />
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <div className="kaleido">
                            <VideoMockup video={"insightsroman"} fullpage />
                        </div>
                        <div className="reel">
                            <Modal open={modalIsOpen} video="roman" toggleModal={toggleModal} />
                        </div>
                        <Wrapper>
                            <div className="flux" data-scroll data-scroll-call="update">
                                <SuperTitle className="home">
                                    <h1>Valentino Insights </h1><h2>Roman Palazzo Art Residency</h2>
                                </SuperTitle>
                                <div className="sheet">
                                    <Sheet position="intro" project="roman" toggleModal={toggleModal} />
                                </div>
                                <VideoMockup video={"insightsroman2"} />
                                <div className="map">
                                    <Map filename="mapRomanEdition.jpg" />
                                </div>
                                <div className="description">
                                    <Description project="roman" paragraph="1" />
                                </div>
                                <VideoMockup video={"insightsroman3"} fullpage />
                                <div className="sheet">
                                    <Sheet direction="row-reverse" position="mockup" project="roman" />
                                </div>
                                <VideoMockup video={"insightsroman4"} />
                                <div className="description pb-100">
                                    <Description project="roman" paragraph="2" />
                                </div>
                                <div className="ipadWhite">
                                    <VideoMockup video={"ipadinsightsroman"} white />
                                </div>
                                <div className="pt0" >
                                    <Mockup project="roman" />
                                </div>
                                <Footer page="insights" />
                            </div>
                        </Wrapper>
                    </main>
                </Layout>
            </div>
        </>
    )
}

export default IndexPage


